// Montserrat

$montserrat-thin: '/assets/fonts/montserrat/Montserrat-Thin.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$montserrat-thin-italic: '/assets/fonts/montserrat/Montserrat-ThinItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$montserrat-extra-light: '/assets/fonts/montserrat/Montserrat-ExtraLight.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$montserrat-extra-light-italic: '/assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$montserrat-light: '/assets/fonts/montserrat/Montserrat-Light.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$montserrat-light-italic: '/assets/fonts/montserrat/Montserrat-LightItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$montserrat-regular: '/assets/fonts/montserrat/Montserrat-Regular.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$montserrat-regular-italic: '/assets/fonts/montserrat/Montserrat-Italic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$montserrat-medium: '/assets/fonts/montserrat/Montserrat-Medium.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$montserrat-medium-italic: '/assets/fonts/montserrat/Montserrat-MediumItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$montserrat-semibold: '/assets/fonts/montserrat/Montserrat-SemiBold.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-semibold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$montserrat-semibold-italic: '/assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-semibold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$montserrat-bold: '/assets/fonts/montserrat/Montserrat-Bold.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$montserrat-bold-italic: '/assets/fonts/montserrat/Montserrat-BoldItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$montserrat-extra-bold: '/assets/fonts/montserrat/Montserrat-ExtraBold.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$montserrat-extra-bold-italic: '/assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

$montserrat-black: '/assets/fonts/montserrat/Montserrat-Black.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$montserrat-black-italic: '/assets/fonts/montserrat/Montserrat-BlackItalic.ttf';
@font-face {
  font-family: 'Montserrat';
  src: url($montserrat-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}
