@use 'variables' as v;

.toast-container {
  width: 23.75rem;

  .ngx-toastr {
    padding: 1rem;
    width: 100%;
  }

  .custom-toast.ngx-toastr {
    background-color: v.$white-color;
    color: v.$primary-text-color;
    border-radius: 0.5rem;
    font-size: 0.875rem;
  }

  .toast-title {
    font-weight: 600;
  }

  .toast-message {
    font-weight: 400;
    color: v.$pure-grey-neutral-600
  }

  .toast-message-container {
    display: flex;
    flex-direction: column;
    padding-right: 0.75rem;
    max-width: 80%;
  }

  .toast-content {
    display: flex;
    flex-direction: row;
  }

  .toast-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .toast-undo-button {
    color: v.$primary-green-color;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .icon-content-container {
    display: flex;
    max-width: calc(95% - 0.5rem);
  }

  .icon-container {
    padding-right: 1rem;
  }

}

@media (max-width: v.$mobile-max-width) {
  .toast-container {
    width: 95%;
  }
}
