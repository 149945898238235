@use 'font' as f;
@use 'variables' as v;

:root {
  --reactive-table-pagination-active-color: #{v.$primary-green-color};

  table.reactive-table {
    position: unset;
  }
}
