@import 'variables';

:root {
  // all
  --button-font-family: 'Montserrat';
  --button-font-weight: 600;
  --button-font-style: normal;
  // info text
  --button-info-font-family: 'Montserrat';
  --button-info-font-weight: 600;
  --button-info-font-style: normal;
  --button-info-font-size: 1rem;
  // defaults
  --button-default-text-color: #{$button-text-color};
  --button-default-background-color: #{$black-color};
  --button-default-hover-color: #{$button-text-hover-color};
  --button-default-padding: 0.75rem 1rem;
  --button-default-border-radius: 0.375rem;
  --button-default-height: 2.75rem;
  --button-default-font-size: 1rem;
  // button rounded
  --button-rounded-text-color: #{$white-color};
  --button-rounded-background-color: #{$dark-grey-color};
  --button-rounded-hover-color: #{$extra-dark-gray-color};
  --button-rounded-disabled-background-color: #{$pure-grey-neutral};
  // clear
  --button-clear-text-color: #{$black-color};
  --button-clear-font-family: 'Montserrat';
  --button-clear-font-weight: 500;
  --button-clear-font-style: normal;
  // destructive
  --button-destructive-text-color: #{$red-color};
  --button-destructive-background-color: #{$white-color};
  --button-destructive-disabled-background-color: #{$red-color};
  // neutral
  --button-neutral-background-color: #{$button-neutral-color};
  --button-neutral-text-color: #{$button-black-text-color};
  --button-neutral-hover-background-color: #{$button-neutral-hover-color};
  --button-neutral-disabled-background-color: #{$button-neutral-color};
  // outlined
  --button-outlined-background-color: #{$white-color};
  --button-outlined-text-color: #{$button-black-text-color};
  --button-outlined-hover-background-color: #{$button-outlined-hover-color};
  // primary
  --button-primary-text-color: #{$white-color};
  --button-primary-background-color: #{$primary-green-color};
  --button-primary-hover-color: #{$hover-green-color};
  --button-primary-disabled-background-color: #{$button-neutral-color};
  // text
  --button-text-color: #{$button-black-text-color};
  --button-text-background-color: #{$button-text-color};
  --button-text-hover-color: #{$button-text-hover-color};
  // inline text
  --button-inline-text-color: #{$button-black-text-color};
  --button-inline-text-font-weight: 600;
  --button-inline-text-background-color: $transparent-color;
  --button-inline-text-hover-color: $transparent-color;

  .button-neutral {
    border: 1px solid #{$pure-grey-neutral-300} !important;
  }

  .button-generic.button-primary:disabled {
    background-color: $pure-grey-neutral-50 !important;
    border: 1px solid $pure-grey-neutral-300;
    color: $button-black-text-color !important;
  }

  .button-generic.button-neutral:disabled {
    background-color: $pure-grey-neutral-50 !important;
    color: $button-black-text-color !important;
  }
}
